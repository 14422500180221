// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  studyNameStore:string,
  token: string;
  categoriesArray: any;
  category: string;
  subCategory: string;
  isVisible: boolean;
  dropdownCategoryStatus: boolean;
  activeModalType: string;
  selectedCategoryID: any;
  study:[{
    id: string;
    type: string;
    attributes: {
        study_name: string;
        study_number: string;
        study_description: string;
        account_id: number;
        chat: boolean;
        community: boolean;
        gamification: boolean;
        archived: boolean;
        published: boolean;
        milestone_managements: {
            data: {
              id: string;
              type: string;
              attributes: {
                  id: number;
                  start_date: string;
                  end_date: string;
                  milestone_description: string;
                  name: string;
              };
          }
        };
        faq_managements: {
            data: {
              id: string;
              type: string;
              attributes: {
                  id: number;
                  question: string;
                  answer: string;
                  images:  {
                    url: string;
                };
                  videos: {
                    url: string;
                }
              };
          }
        };
    };
}] | "",
  showStudy:boolean;

  oldPassword: any,
  newPassword: any,
  newConfirmPassword: any,
  oldPasswordError: any,
  newPasswordError: any,
  newConfirmPasswordError: any,
  passwordDialogOpen: boolean,
  showOldPassword:boolean,
  showNewPassword:boolean,
  showNewConfirmPassword:boolean,
  loading : boolean,
  languageSwitch: {
    SelectStudy: string,
    StudyNumber: string,
    NoStudyFound: string
  }
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectTemplatesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProjectApiCallId: string='';
  getStudyDataApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      showLoader: false,
      // Customizable Area Start
      studyNameStore: "",
      token: "",
      categoriesArray: [],
      category: "",
      subCategory: "",
      isVisible: false,
      dropdownCategoryStatus: false,
      activeModalType: "",
      selectedCategoryID: [],
      study: "",
      showStudy:true,
      oldPassword: "",
      newPassword: "",
      newConfirmPassword: "",
      oldPasswordError: "",
      newPasswordError: "",
      newConfirmPasswordError: "",
      passwordDialogOpen: false,
      showOldPassword:false,
      showNewPassword:false,
      showNewConfirmPassword:false,
      loading : true,
      languageSwitch: {
        SelectStudy: "",
        StudyNumber: "",
        NoStudyFound: ""
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

     
       
      if(apiRequestCallId === this.getStudyDataApiCallId){
        
       
        if (responseJson.data?.length > 0) {
          this.setState({
            study: responseJson.data,
          });
        }
        this.setState({
          loading: false
        })
        
      }
    }
    
    // Customizable Area End
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
   super.componentDidMount();
   this.getToken();
   this.getSelectedLanguage();
   if(!localStorage.getItem("token")) this.props.navigation.navigate('EmailAccountRegistration');
   this.getStudyDataIndexDetail();

   
  }

  getSelectedLanguage = async () => {
    let selLanguage = await getStorageData('lang') || "en";
    this.setState({ selectedLanguage: selLanguage }, () => {
      this.getInitialValues();
    });
  };

  getInitialValues = ()=>{
  
    this.setState({
      languageSwitch: {
        ...configJSON.translations[this.state.selectedLanguage],
      }
    });

}


  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };


  


  

  

  

  getStudyDataIndexDetail = () => {
    let token = typeof window !== "undefined" ? localStorage.getItem("token") : null
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getStudyDataApiCallId = requestMessage.messageId;
  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectAPiEndPoint
  );
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return true;

  }
 

  ProjectTemplateApi = async() => {
    this.setState({ showLoader: true });
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProjectApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  gotoBack = () => {this.props.navigation.goBack(null)}

  
  navigateToLogin() {
    if (typeof window !== 'undefined') localStorage.removeItem('token');

    const navigateToLoginMessage: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    navigateToLoginMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigateToLoginMessage);
  }
  // Customizable Area End
}