import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {setStorageData} from "../../../framework/src/Utilities"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  seconds: number;
  open: boolean;
  otpScreen: boolean;
  emailScreen: boolean;
  emailErrorText: string;
  passwordSuccess: boolean;
  emailError: any;
  otpError: any
  otp: any;
  validOtp: boolean;
  confirmPassword: any;
  passwordError: any;
  confirmPasswordError: any
  token: string,
  otpConfirm: string;
  isLoading: boolean;
  openSnackbar: boolean;
  showPrivacy:boolean,
  timerActive:boolean,
  showTerms:boolean,
  showPassword:boolean,
  showConfirmPassword:boolean,
  roleId:number,
  fullName:string,
  termsAndConditions:any,
  privacyPolicy: any,
  languageMode : boolean,
  selectedLanguage : string,
  languageSwitch: {
    pageHeader: string,
    emailBoxHeader: string,
    emailInputPlaceholder: string,
    continueBtnText: string,
    tnc: string,
    last: string,
    terms: string,
    and: string,
    prp: string,
    sixDigitOtp: string,
    didNotGetOtp: string,
    resendOtp: string,
    submitBtn: string,
    setPass: string,
    setPassMsg: string,
    EnterNewPassPh: string,
    confirmNewPassPh: string,
    changePassBtn: string,
    welcomeMsg: string,
    passwordCreatedMsg: string,
    loginWithPass : string,
    editEmailMsg : string,
    invalidEmailErr: string,
    unverifiedEmailErr: string,
    wrongOtpEr: string,
    passwordNotMatchErr: string,
    password15CharErr : string,
    passwordSyntaxErr : string,
    passwordMatchErr: string,
    confirmPassErr : string,
    otpExpiredErr : string,
    createPasswordBtn : string,
    language: string
  };
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  emailRegistrationAPIid: any;
  emailOTPConfirmationAPIid: any;
  passwordRegistrationAPIid: any;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  countdown: any;
  getTermsandConditionApiCallId: string = "";
  getPrivacyPolicyApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      seconds: 60,
      open: false,
      otpScreen: false,
      emailScreen: true,
      emailErrorText: "",
      passwordSuccess: false,
      emailError: null,
      otpError: null,
      otp: null,
      validOtp: true,
      confirmPassword: "",
      passwordError: "",
      confirmPasswordError: "",
      token: "",
      otpConfirm: "",
      isLoading: false,
      openSnackbar: false,
      showPrivacy:false,
      timerActive:true,
      showTerms:false,
      showPassword:false,
      showConfirmPassword:false,
      roleId:0,
      fullName:'',
      termsAndConditions:'',
      privacyPolicy: '',
      languageMode : false,
      selectedLanguage : "en",
      languageSwitch: {
        pageHeader: '',
        emailBoxHeader: '',
        emailInputPlaceholder: '',
        continueBtnText: '',
        tnc: '',
        last: '',
        terms: '',
        and: '',
        prp: '',
        sixDigitOtp: '',
        didNotGetOtp: '',
        resendOtp: '',
        submitBtn: '',
        setPass: '',
        setPassMsg: '',
        EnterNewPassPh: '',
        confirmNewPassPh: '',
        changePassBtn: '',
        welcomeMsg: '',
        passwordCreatedMsg: '',
        loginWithPass : '',
        editEmailMsg : '',
        invalidEmailErr: '',
        unverifiedEmailErr: '',
        wrongOtpEr: '',
        passwordNotMatchErr: '',
        password15CharErr : '',
        passwordSyntaxErr : '',
        passwordMatchErr: '',
        confirmPassErr : '',
        otpExpiredErr : '',
        createPasswordBtn :'',
        language: ''
      }
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      await this.handleRestApiResponse(message);
    }
  
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      await this.handleNavigationPayload(message);
    }
  
    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      this.handleCountryCode(message);
    }
    
  }
  
  private async handleRestApiResponse(message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
  
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
  
    if (apiRequestCallId && responseJson) {
      await this.processApiResponse(apiRequestCallId, responseJson, errorReponse);
    }
  }
  
  private async processApiResponse(apiRequestCallId: string, responseJson: any, errorReponse: any) {
    switch (apiRequestCallId) {
      case this.validationApiCallId:
        this.handleValidationApiResponse(responseJson);
        break;
      case this.createAccountApiCallId:
        this.handleCreateAccountApiResponse(responseJson, errorReponse);
        break;
      case this.emailRegistrationAPIid:
        this.handleEmail(responseJson);
        break;
      case this.emailOTPConfirmationAPIid:
        this.handleEmailOTP(responseJson);
        break;
      case this.passwordRegistrationAPIid:
        this.handlePasswordRegistration(responseJson, errorReponse);
        break;
      case this.getTermsandConditionApiCallId:
        this.setState({ termsAndConditions: responseJson.data.description });
        break;
      case this.getPrivacyPolicyApiCallId:
        this.setState({ privacyPolicy: responseJson.data.description });
        break;
    }
  }
  
  private handleValidationApiResponse(responseJson: any) {
    if (this.arrayholder && this.arrayholder.length !== 0) {
      const regexData = this.arrayholder[0];
  
      if (regexData.password_validation_regexp) {
        this.passwordReg = new RegExp(regexData.password_validation_regexp);
      }
  
      if (regexData.password_validation_rules) {
        this.setState({
          passwordHelperText: regexData.password_validation_rules,
        });
      }
  
      if (regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  }
  
  private handleCreateAccountApiResponse(responseJson: any, errorReponse: any) {
    if (!responseJson.errors) {
      const msg: Message = new Message(
        getName(MessageEnum.AccoutResgistrationSuccess)
      );
  
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  
      this.send(msg);
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  
    this.parseApiCatchErrorResponse(errorReponse);
  }
  
  private handlePasswordRegistration(responseJson: any, errorReponse: any) {
    if (responseJson && !errorReponse) {
      this.handleSetPassword(responseJson);
    }
  }
  
  private async handleNavigationPayload(message: Message) {
    const otpAuthTkn = message.getData(
      getName(MessageEnum.AuthTokenDataMessage)
    );
  
    if (otpAuthTkn && otpAuthTkn.length > 0) {
      this.setState({ otpAuthToken: otpAuthTkn });
      runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
      runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
    }
  }
  
  private handleCountryCode(message: Message) {
    const selectedCode = message.getData(
      getName(MessageEnum.CountyCodeDataMessage)
    );
  
    if (selectedCode !== undefined) {
      this.setState({
        countryCodeSelected:
          selectedCode.indexOf("+") > 0 ? selectedCode.split("+")[1] : selectedCode,
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  otpTimer = () => {
    clearInterval(this.countdown);
    this.countdown = setInterval(() => {
      if (this.state.seconds > 0) {
        this.setState((prevState) => ({
          seconds: prevState.seconds - 1,
        }));
      } else {
        if (this.state.timerActive) {
          this.handleResendOtp();
          clearInterval(this.countdown);
          this.setState({ timerActive: false });
        }
      }
    }, 1000);
  };

  handleEmailOTP = (responseJson: any) => {
    if(responseJson.data){
      this.setState({ open: true, isLoading: false, roleId:responseJson.data.attributes.role_id, fullName:responseJson.data.attributes.full_name});
      return true;
    }
    else {
      this.setState({
        otpError : responseJson.error.message,
        isLoading : false
      })
      return false;
    }
    
   

  }

  handleEmail = (responseJson: any) => {
    if (responseJson && responseJson.error) {
      if (responseJson.error.message === "Please enter valid email address") {
        this.setState({ emailError: true, emailErrorText: this.state.languageSwitch.invalidEmailErr, isLoading: false })
      }
    } else {
      if (responseJson.data?.message === "Account already activated") {
        this.setState({ emailError: true, emailErrorText: "Account already activated", isLoading: false })
        this.props.navigation.navigate("EmailAccountLoginBlock")
      } else {
        this.setState({ token: responseJson.data?.token, emailScreen: false, otpScreen: true, isLoading: false }, () => {
          this.otpTimer()
        })
      }
    }
  }


  handleSetPassword = (responseJson: any) => {
    this.setState({ open: false, otpScreen: false, passwordSuccess: true, isLoading: false });
  }
  
  async componentDidMount() {
    super.componentDidMount();
    setStorageData("lang",'en');
    this.getInitialValues();
  
  }

  async componentWillUnmount() {
    clearInterval(this.countdown);
  }

  displayTime() {
    const minutes = Math.floor(this.state.seconds / 60);
    const remainingSeconds = this.state.seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const dataobj = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: dataobj,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  postEmailRegistration = () => {
    setStorageData("email",this.state.email)
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };

    let formDataCampaign = {
      "data": {
        "type": "email_account",
        "attributes":
        {
          "email": this.state.email.toLowerCase(),
          "role_name" : "Sponsor"
        }
      }
    }
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.emailRegistrationAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formDataCampaign)
    );
    this.emailRegistrationAPIid = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  postEmailOTPConfirmation = () => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
      locale:this.state.selectedLanguage
    };

    let formDataCampaignOtp = {
      "pin": this.state.otp
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.emailOTPConfirmationAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formDataCampaignOtp)
    );
    this.emailOTPConfirmationAPIid = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  postPasswordRegistration = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };

    let formDataCampaignPassword = {
      "data": {
        "type": "email_account",
        "attributes": {
          "full_name": this.state.fullName,
          "email": this.state.email,
          "password": this.state.password,
          "role_id": this.state.roleId,
        }
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRegistrationAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formDataCampaignPassword)
    );
    this.passwordRegistrationAPIid = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  handleClickOpen = () => {
    if (this.state.otp === null || this.state.otp?.length !== 6) {
      this.setState({ validOtp: false })
    }
    else {
      this.postEmailOTPConfirmation()
      this.setState({
        isLoading: true
      })
    }
  };

  handleClose = () => {
    this.setState({ open: false, password:'', confirmPassword:'', passwordError:'', confirmPasswordError:'', showPassword:false, showConfirmPassword:false });
  };
  handleEmailSubmit = () => {
    if (this.state.emailError === false) {
      this.postEmailRegistration()
      this.setState({ isLoading: true })

    } else {
      this.setState({ emailError: true, emailErrorText: this.state.languageSwitch.invalidEmailErr })
    }
  }

  submitPassWord = () => {
    if (this.state.password === "" && this.state.confirmPassword === "") {
      this.setState({ passwordError: configJSON.passwordError, confirmPasswordError: this.state.languageSwitch.confirmPassErr })
    } else if (this.state.password === "") {
      this.setState({ passwordError: configJSON.passwordError })
    } else if (this.state.confirmPassword === "") {
      this.setState({ confirmPasswordError: this.state.languageSwitch.confirmPassErr })
    } else if (this.state.password !== this.state.confirmPassword) {
      this.setState({ confirmPasswordError: this.state.languageSwitch.passwordMatchErr })
    }
    else if (this.state.passwordError === "" && this.state.confirmPasswordError === "") {
      this.postPasswordRegistration();
      this.setState({
        isLoading: true
      })

    }
  }

  handleEmailChange = (value: string) => {
    const regex = configJSON.emailRegex;
    if (value.trim() === '') {
      this.setState({ emailError: true, emailErrorText: '', email: value });
    } else if (value.match(regex)) {
      this.setState({ emailError: false, emailErrorText: '', email: value });
    } else {
      this.setState({ emailError: true, emailErrorText: this.state.languageSwitch.invalidEmailErr, email: value });
    }
  }

  handleOtpChange = (e: any) => {
    const input = e.target.value;
    const otp = input.replace(/\D/g, ''); 
    this.setState({otpError:""})
  
    if (otp.length > 6) {
      
      this.setState({
        otp: otp.slice(0, 6),
        validOtp: true
      });
    } else {
      this.setState({
        otp: otp,
        validOtp: otp.length === 6 || otp.length === 0 
      });
    }
  };

  handlePasswordChange = (value: any) => {
  
    let passwordError = '';
    const strongPasswordRegex = configJSON.passwordRegex;
    if (value !== '') {
      if (value.length > 15) {
        passwordError = this.state.languageSwitch.password15CharErr;
      } else {
      passwordError = strongPasswordRegex.test(value)
        ? ''
        : this.state.languageSwitch.passwordSyntaxErr;
    }
  }
    this.setState((prevState) => ({
      password: value,
      passwordError,
      confirmPasswordError: value === prevState.confirmPassword ? '' : prevState.confirmPasswordError,
    }));
  };

  handleConfirmPasswordChange = (value: any) => {
    let confirmPasswordError = '';
    if (value !== '' && value !== this.state.password) {
      confirmPasswordError = this.state.languageSwitch.passwordMatchErr;
    }
    this.setState({
      confirmPassword: value,
      confirmPasswordError: confirmPasswordError,
    });
  };

  loginNavigationButton = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }

  handleResendOtp = () => {
    if (!this.state.timerActive) {
      this.postEmailRegistration();
      this.setState({ openSnackbar: true, seconds: 60, timerActive: true });
      this.otpTimer();
    }
  };

  handleSnackbarClose = (reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openSnackbar: false });
  };

  handleEditEmailAddress = () => {
    clearInterval(this.countdown);
    this.setState({
      otpScreen: false, emailScreen: true, otp: null, seconds: 60, timerActive: true, validOtp : true
    })
  }

  handleTermsClick = () => {
    this.getTermsAndCondition();
    this.setState({showTerms:true})
   };
   handlePrivacyClick = () => {
    this.getPrivacyPolicy();
    this.setState({showPrivacy:true})
   }
   handleCloseTerms = () => {
    this.setState({showTerms:false})
   }
   handleClosePrivacy = () => {
    this.setState({showPrivacy:false})
   }
   handleClickShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword
    }))
   }
   handleClickShowConfirmPassword = () => {
    this.setState((prevState) => ({
      showConfirmPassword: !prevState.showConfirmPassword
    }))
   }
   getTermsAndCondition = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTermsandConditionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTermsAndConditionApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getPrivacyPolicy = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPrivacyPolicyApiCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNewPrivacyPolicyApiEndPoint
    );

   

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  navigateToSignUp=()=>{
    this.setState({otpScreen : false,
    passwordSuccess : false,
  emailScreen : true,email:"",emailError : true});
 }

 handleToggleLanguageMode = ()=>{
      this.setState({languageMode : !this.state.languageMode})
 }
 handleLanguageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
  const newLanguage = event.target.value as string;
  const translations = configJSON.translations[newLanguage];

  this.setState({
    selectedLanguage: newLanguage,
    languageSwitch: {
      pageHeader: translations.pageHeader,
      emailBoxHeader: translations.emailBoxHeader,
      emailInputPlaceholder: translations.emailInputPlaceholder,
      continueBtnText: translations.continueBtnText,
      tnc: translations.tnc,
      last: translations.last,
      terms: translations.terms,
      and: translations.and,
      prp: translations.prp,
      sixDigitOtp: translations.sixDigitOtp,
      didNotGetOtp: translations.didNotGetOtp,
      resendOtp: translations.resendOtp,
      submitBtn: translations.submitBtn,
      setPass: translations.setPass,
      setPassMsg: translations.setPassMsg,
      EnterNewPassPh: translations.EnterNewPassPh,
      confirmNewPassPh: translations.confirmNewPassPh,
      changePassBtn: translations.changePassBtn,
      welcomeMsg: translations.welcomeMsg,
      passwordCreatedMsg: translations.passwordCreatedMsg,
      loginWithPass: translations.loginWithPass,
      editEmailMsg: translations.editEmailMsg,
      invalidEmailErr: translations.invalidEmailErr,
      unverifiedEmailErr: translations.unverifiedEmailErr,
      wrongOtpEr: translations.wrongOtpEr,
      passwordNotMatchErr: translations.passwordNotMatchErr,
      password15CharErr: translations.password15CharErr,
      passwordSyntaxErr: translations.passwordSyntaxErr,
      passwordMatchErr: translations.passwordMatchErr,
      confirmPassErr: translations.confirmPassErr,
      otpExpiredErr: translations.otpExpiredErr,
      createPasswordBtn: translations.createPasswordBtn,
      language: translations.language
    }
  });

  setStorageData('lang', newLanguage);
};

getInitialValues = ()=>{
  
      this.setState({
        languageSwitch: {
          ...configJSON.translations[this.state.selectedLanguage],
        }
      });

}
  // Customizable Area End
}
