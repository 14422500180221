// @ts-nocheck
import React from "react";

// Customizable Area Start
import {NoStudyFoundLogo} from "../../projecttemplates/src/assets"
import SideNavbar from "./SideNavbar.web";
import {
  Typography,
  // Customizable Area Start
  Container,
  Grid,
  Box,
  styled,
  Card,
  CardContent,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";


// Customizable Area End

import ProjectTemplatesController, {
  Props,
  configJSON,
} from "./ProjectTemplatesController";

export default class ProjectTemplates extends ProjectTemplatesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
 
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      <SideNavbar showStudy={true} navigation={this.props.navigation} id={this.props.id} showDropDown={false}/>
      <Container maxWidth="lg">
        
      <CardStyle>
        {this.state.loading ? <Box className="wrapper" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%", width: "100%"  }}> <CircularProgress style={{color:"#a045fb"}} /> </Box>:  <>{this.state.study && this.state.study.length > 0 ? (
          <Box className="wrapper">
            <Typography className="selectText">{this.state.languageSwitch.SelectStudy}</Typography>
            <Box className="main">
              <Grid container item spacing={2} xs={12} className="gridBox">
                {this.state.study?.map((std: any) => {
                  return (
                    <>
                      <Card className="card"  key={std.attributes.study_name} onClick={() => {this.props.navigation.navigate("CustomisableUserProfiles");localStorage.setItem('studyName',std.attributes.study_name); localStorage.setItem('studyNumber',std.id)}} >
                        <CardContent  id="card-content">
                          <Typography className="studyname">{std.attributes.study_name}</Typography>
                          <Typography className="studyNum">{this.state.languageSwitch.StudyNumber}: {std.attributes.study_number}</Typography>
                        </CardContent>
                      </Card>
                    </>
                  )
                })}
              </Grid>
            </Box>
          </Box>) : (<Grid item xs={12} container direction="column" justifyContent="center" alignItems="center" style={{ height: '80vh' }} >
            <Box>
              <img src={NoStudyFoundLogo} alt="NoStudyFoundLogoName" />
            </Box>
            <Box>
              <Typography className="noStudyText">{this.state.languageSwitch.NoStudyFound}</Typography>
            </Box>
          </Grid>)}</> }
      
      </CardStyle>
      </Container>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CardStyle = styled((Box as React.FC<BoxProps>))({
  "& .card": {
    cursor: 'pointer',
    width: '100%',
    maxWidth: "320px",
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: "20px",
    background: "#FFF",
    padding: '20px 10px'
  },
  "& .gridBox": {
    paddingTop: '50px',
    padding: '20px',
    gap: '32px',
    justifyContent : "center"
  },
  "& .main": {
    display: 'flex',
    alignItems: 'center',
    justifyContent: "center"
  },
  "& .selectText": {
    color: "#1F3650",
    textAlign: "center",
    fontFamily: "Jost",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 600,

  },
  "& .wrapper": {
    paddingTop: '100px',
  },
  "& .studyname": {
    color: "#292929",
    textAlign: "center",
    fontFamily: "Jost",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 600,
    overflowWrap : "break-word"
  },
  "& .studyNum": {
    paddingTop: '28px',
    color: "#292929",
    textAlign: "center",
    fontFamily: "Jost",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600
  },
  "& .noStudyText": {
    paddingTop: '34px',
    color: "#000",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: 400
  }
});
// Customizable Area End